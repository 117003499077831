




















import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import {Vue, Component, Prop} from 'vue-property-decorator'

import { OrganisationDocumentResource } from '@/models/organisationDocuments/OrganisationDocumentResource'
import {IOrganisationDocumentVersionResource} from '@/models/organisationDocuments/OrganisationDocumentVersion'

import SmallLoader from '@/components/loaders/SmallLoader.vue'
import VersionCard from '@/components/document/VersionCard.vue'

@Component({
  components: {
    SmallLoader,
    VersionCard
  }
})
export default class DocumentDetailHistoryPartial extends Vue {

  @Prop({type: Object, required: true})
  private data!: OrganisationDocumentResource | null

}
