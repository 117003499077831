
















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import {OrganisationDocumentVersionResource} from '@/models/organisationDocuments/OrganisationDocumentVersion'

@Component({
  name: 'VersionCard'
})
export default class VersionCard extends  Vue {

  @Prop()
  private version!: OrganisationDocumentVersionResource

  @Prop({ default: false })
  private isChild!: boolean

  private showChildrenFold: boolean = false

  private get isUpdatingAnotherDocument(): boolean {
    return this.version.type === 'clone' && this.version.status === 'inactive'
  }

  private get derivedDocumentIsBeingCreated(): boolean {
    return this.version.type === 'reference' && this.version.status === 'inactive'
  }

  private get statusString(): string {
    const statusString = this.version.status.replace('-', ' ')
    return statusString.charAt(0).toUpperCase() + statusString.slice(1)
  }

  private toggleChildrenFold(): void {
    this.showChildrenFold = !this.showChildrenFold
  }

}
