






import { Component, Vue }   from 'vue-property-decorator'

import { organisationDocumentsModule }  from '@/store'
import { OrganisationDocumentResource } from '@/models/organisationDocuments/OrganisationDocumentResource'

import DocumentDetailHistoryPartial from '@/components/document/History.vue'

@Component({
  components: {
    DocumentDetailHistoryPartial
  }
})
export default class DocumentDetailHistory extends Vue {
  private get detail(): OrganisationDocumentResource | null {
    return organisationDocumentsModule.detail
  }
}
